import React from 'react'
import SEO from '../components/seo'

const NotFoundPage = ({language}) => {
    return (
        <>
            <SEO
                title="Síða fannst ekki"
                description="Síða fannst ekki"
            />
            <div>
                <h2>404: Not found</h2>
                {/* <h3>{localization[language].frontpage.labelPageNotFound}</h3> */}

                {/* <a href="mailto:tilkynna@tilkynna.is">{localization[language].frontpage.labelContact}</a> */}
            </div>
        </>
    )
}

export default NotFoundPage
